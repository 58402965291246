import { lazy, useEffect } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AllDBelPagesLayout } from '../pages/AllDBelPagesLayout';
import { AllSignUpPagesLayout } from '../pages/signup/AllSignUpPagesLayout';
import { getDBelApiCredentials, useLazyGetProductsQuery } from '@dbel/react-commons/api';
import AuthGuard from '../components/AuthGuard';
import { AllErrorPagesLayout, Loadable, SplashScreen } from '@dbel/react-commons/components';
import { AppRoutes } from './AppRoutes';
import {
  AuthorizationRequired,
  DBelInventory,
  DBelOrganisations,
  Forbidden,
  ImmiWea,
  NotFound,
  PaymentRequired,
  ServerError,
} from './loadables';
import portalInstance from '../locales/i18n';
import useAuth from '../hooks/useAuth';

// project pages are internal
const AccountDetailPage = Loadable(lazy(() => import('../pages/account/AccountDetailPage')));
const CreatingAccountPage = Loadable(lazy(() => import('../pages/signup/CreatingAccountPage')));
const CreateAccountPage = Loadable(lazy(() => import('../pages/signup/CreateAccountPage')));

export const PlatformRoutes = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [getProducts] = useLazyGetProductsQuery();

  useEffect(() => {
    if (!auth.isInitialized && !auth.isAuthenticated) return;
    if (location.pathname !== '/') return;

    const localGetProducts = async () => {
      const products = await getProducts(undefined, false);

      if (products.data && products?.data[0]) {
        navigate(`app/${products.data[0].product.key.toLowerCase()}/projects`);
      } else {
        navigate(`account`);
      }
    };
    localGetProducts();
  }, [auth, getProducts, location.pathname, navigate]);

  return (
    <>
      {auth.isInitialized ? (
        <Routes>
          <Route
            path="/"
            element={
              <AuthGuard>
                <Outlet />
              </AuthGuard>
            }
          >
            <Route path="/" element={<AllDBelPagesLayout />}>
              <Route path="account/*" element={<AccountDetailPage />} />
              <Route path="app/*" element={<AppRoutes />} />
              <Route
                path="inventory/*"
                element={<DBelInventory apiCredentials={getDBelApiCredentials()} language={portalInstance.language} />}
              />
              <Route
                path="admin/*"
                element={
                  <DBelOrganisations apiCredentials={getDBelApiCredentials()} language={portalInstance.language} />
                }
              />
              <Route
                path="immi-wea/*"
                element={<ImmiWea apiCredentials={getDBelApiCredentials()} language={portalInstance.language} />}
              />
            </Route>
            <Route element={<AllSignUpPagesLayout />}>
              <Route path="signup" />
              <Route path="createAccount" element={<CreateAccountPage />} />
              <Route path="creatingAccount" element={<CreatingAccountPage />} />
            </Route>
          </Route>
          {/** shows login page */}
          <Route path="authentication/login" element={<AuthGuard />} />
          <Route element={<AllErrorPagesLayout />}>
            <Route path="401" element={<AuthorizationRequired />} />
            <Route path="402" element={<PaymentRequired />} />
            <Route path="403" element={<Forbidden />} />
            <Route path="404" element={<NotFound />} />
            <Route path="500" element={<ServerError />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      ) : (
        <SplashScreen />
      )}
    </>
  );
};
